import { Link } from 'gatsby'
import React from 'react'

// Components
import Contact from '../components/contact'
import Egg from '../components/egg'
import IntroTextSlider from '../components/intro-slider'
import Layout from '../components/layout'
import SEO from '../components/seo'

// Assets
// import tracer_bg from '/static/img/home/tracer.mp4'
// import koji_bg from '/static/img/home/koji.mp4'
import collins_collins_bg from '/static/img/home/collins-collins.mp4'
import playground_bg from '/static/img/home/playground.mp4'
import state_library_exchange_bg from '/static/img/home/state-library-exchange.mp4'

import brunswick_yard_bg from '/static/img/home/brunswick-yard.jpg'
import etc_bg from '/static/img/home/etc.jpg'
// import mycelium_bg from '/static/img/home/mycelium.mp4'
// import reputation_bg from '/static/img/home/reputation.jpg'
// import martay_bg from '/static/img/home/martay.jpg'

const Index = () => {
  const hideOverflow = () => {
    const main = document.querySelector('main')
    main.style.overflow = 'hidden'
  }

  return (
    <>
      <Layout>
        <SEO title="Home" />
        <section className="intro">
          <div className="left-column">
            <h1>
              <span>Timothy Jones</span>
              <span>Portfolio</span>
            </h1>
            <p>
              Hello, my name is Timothy, and I design and develop web applications. I strongly
              believe in attention to detail, and apply this to every aspect of my code and designs.
            </p>
          </div>
          <div className="right-column">
            <IntroTextSlider />
          </div>
        </section>
        <div className="title-row">
          <div className="left-column">
            <h1>Works</h1>
          </div>
          <div className="title-divider pattern-diagonal-stripes-sm"></div>
        </div>
        <a
          className="work-link"
          href="https://collinscollins.melbourne"
          target="_blank"
          rel="noreferrer"
          cursor-class="select"
        >
          <section className="work-section">
            <div className="left-column">
              <div className="project-info">
                <span>Commercial Leasing</span>
                <span>Re-defining Office Spaces</span>
              </div>
              <time>2023</time>
            </div>
            <div className="right-column">
              <h1 className="project-name">CollinsCollins</h1>
            </div>
            <div className="view-work">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                space="preserve"
              >
                <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
              </svg>
            </div>
            <div className="background">
              <video loop autoPlay playsInline muted>
                <source src={collins_collins_bg} type="video/mp4" />
              </video>
            </div>
          </section>
        </a>
        <a
          className="work-link"
          href="https://statelibraryexchange.com.au"
          target="_blank"
          rel="noreferrer"
          cursor-class="select"
        >
          <section className="work-section reverse">
            <div className="divider pattern-dots-sm"></div>
            <div className="left-column">
              <div className="project-info">
                <span>Innovation</span>
                <span>Smart Technology</span>
              </div>
              <time>2023</time>
            </div>
            <div className="right-column">
              <h1 className="project-name">State Library Exchange</h1>
            </div>
            <div className="view-work">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                space="preserve"
              >
                <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
              </svg>
            </div>
            <div className="background">
              <video loop autoPlay playsInline muted>
                <source src={state_library_exchange_bg} type="video/mp4" />
              </video>
            </div>
          </section>
        </a>
        <a
          className="work-link"
          href="https://playgroundstudio.com.au"
          target="_blank"
          rel="noreferrer"
          cursor-class="select"
        >
          <section className="work-section third">
            <div className="left-column">
              <div className="project-info">
                <span>Digital Agency</span>
                <span>Design & Development</span>
              </div>
              <time>2023</time>
            </div>
            <div className="right-column">
              <div className="divider pattern-diagonal-lines-sm"></div>
              <h1 className="project-name">Playground Studio</h1>
            </div>
            <div className="view-work">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                space="preserve"
              >
                <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
              </svg>
            </div>
            <div className="background">
              <video loop autoPlay playsInline muted>
                <source src={playground_bg} type="video/mp4" />
              </video>
            </div>
          </section>
        </a>
        <section className="work-section reverse double">
          <div className="left-column">
            <svg>
              <line x1="0" y1="100%" x2="100%" y2="0" />
            </svg>
          </div>
          <div className="right-column">
            <a
              href="https://etc-landing-site.vercel.app"
              target="_blank"
              className="work-section--small-link"
              rel="noreferrer"
              cursor-class="select"
            >
              <div className="work-section--small">
                <div className="top">
                  <div className="background">
                    <img src={etc_bg} alt="etc. landing page" />
                    <div className="overlay"></div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    space="preserve"
                  >
                    <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
                  </svg>
                  <h1 className="project-name">etc.</h1>
                </div>
                <div className="bottom">
                  <div className="project-info">
                    <span>Commercial Real Estate</span>
                  </div>
                  <time>2023</time>
                </div>
              </div>
            </a>
            <div className="spacer-mobile">
              <svg>
                <line x1="0" x2="100%" y1="0" y2="100%" />
              </svg>
            </div>
            <a
              href="https://brunswickyard.com.au"
              className="work-section--small-link"
              target="_blank"
              rel="noreferrer"
              cursor-class="select"
            >
              <div className="work-section--small right">
                <div className="top">
                  <div className="background">
                    <img src={brunswick_yard_bg} alt="Brunswick Yard landing page" />
                    <div className="overlay"></div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    space="preserve"
                  >
                    <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
                  </svg>
                  <h1 className="project-name">Brunswick Yard</h1>
                </div>
                <div className="bottom">
                  <div className="project-info">
                    <span>New Development</span>
                  </div>
                  <time>2023</time>
                </div>
              </div>
            </a>
          </div>
        </section>
        <Link
          className="work-link"
          to="/profile"
          onClick={hideOverflow}
          cursor-class="select-inverted"
        >
          <section className="work-section profile">
            <div className="divider pattern-diagonal-lines-sm"></div>
            <div className="profile-box-anim">
              <div className="profile-box"></div>
              <div className="profile-box--hidden">
                <div className="circle-top"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  space="preserve"
                >
                  <polygon points="33.6,12.8 33.6,20.9 73.3,20.9 12.6,81.7 18.3,87.4 79.1,26.7 79.1,66.4 87.2,66.4 87.2,12.8 " />
                </svg>
              </div>
            </div>
            <h1>Profile</h1>
          </section>
        </Link>
        <Contact />
        <footer>
          <div className="left-column">
            <h1>&copy; Timothy Jones</h1>
            <Link to="/surprise">
              <Egg className="surprise" onClick />
            </Link>
          </div>
          <div className="right-column">
            <Link
              className="profile-link"
              to="/profile"
              onClick={hideOverflow}
              cursor-class="select"
            >
              <p>
                <strong>Profile</strong>
              </p>
              <p>This is my portfolio site, showcasing all of my works throughout recent years.</p>
              <p>
                I am incredibly passionate about web development, and I channel this through
                creating memorable digital experiences which can be seen in my works.
              </p>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1000 1000"
                enableBackground="new 0 0 1000 1000"
                space="preserve"
              >
                <g>
                  <path d="M10,535h846L662.1,728.9l49.4,49.5L990,500L711.5,221.5l-49.4,49.5L856,465H10V535z" />
                </g>
              </svg>
            </Link>
          </div>
        </footer>
      </Layout>
    </>
  )
}

export default Index
